
import React from "react";

function Education() {
    return (
        <div className="section">
            <h2>Education</h2>
            <div className="section">
                <div className="job">
                    <div className="job-title">Lebanese University, Beirut, Lebanon</div>
                    <div className="job-details">Masters’ in Computer Science - Web Development</div>
                    <div className="job-duration">Oct 2008 - Jun 2010</div>
                </div>
            </div>
        </div>
    );
}

export default Education;